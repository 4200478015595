import React, { forwardRef, useImperativeHandle, useRef } from 'react';

interface WorkflowCardProps {
    title: string;
    description: string;
    className?: string;
}

const WorkflowCard = forwardRef<HTMLDivElement, WorkflowCardProps>(({ title, description, className }, ref) => {
    const localRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => localRef.current as HTMLDivElement);
    return (   
        <div ref={localRef} className={`bg-gray-50 dark:bg-agantar-dark-blue  border border-black/10 shadow rounded-lg px-5 py-4 md:max-w-[50%] mx-auto mb-16 ${className}`}>
            <h4 className="mb-4">{title}</h4>
            <p className="bg-white dark:bg-black/20  border border-gray-100 dark:border-black/10 p-4 rounded-lg">{description}</p>
        </div>
    );
});

export default WorkflowCard;