import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/styles/layout/Breadcrumb.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface Link {
    title: string;
    url: string;
}
//Array of link
const Breadcrumb = ({ title, links }: { title:string, links: Link[] }) => {
    const location = useLocation();
    const [activeHash, setActiveHash] = useState("");
    const [showMobile, setShowMobile] = useState(false);
    const mobileMenuRef = useRef<HTMLDivElement | null>(null);

    const toggleMobile = () => {  
        if( !mobileMenuRef.current) return;

        if (!showMobile) {
            // Ouvre le menu en mesurant la hauteur réelle
            const scrollHeight = mobileMenuRef.current.scrollHeight + 12 +"px";
            mobileMenuRef.current.style.height = scrollHeight; 
        } else {
            // Ferme le menu en fixant la hauteur avant de l'animer vers 0
            const height = mobileMenuRef.current.offsetHeight + "px";
            mobileMenuRef.current.style.height = height; // Fixe la hauteur actuelle
            setTimeout(() => {
                if (mobileMenuRef.current) {
                    mobileMenuRef.current.style.height = "0px";
                }
            }, 10);
        }
    
        setShowMobile(!showMobile);
    }

    useEffect(() => {
        setActiveHash(location.hash); // Met à jour l'état avec l'ancre actuelle
    }, [location]);

    return (
        <div id="breadcrumb">
            <div className="flex justify-between items-center gap-3 pb-2 max-w-7xl mx-auto px-4">
                <p className="md:text-xl font-bold m-0">{title}</p>

                <div className="flex-1"></div>	

                <div className="bdc-items text-sm flex gap-5 lg:flex hidden">
                    {links.map((link, index) => (
                        <a  key={index} href={link.url} 
                            className={`bdc-item ${activeHash === link.url ? 'active' : ''}`}>
                            {link.title}
                        </a>
                    ))}
                </div>

                <div className="bdc-mobile lg:hidden">
                    <button className="p-1" onClick={toggleMobile}>
                        <FontAwesomeIcon icon={faChevronDown} className={`transition ${showMobile ? 'rotate-180' : '' }`}/>
                    </button>
                </div>
                <a className="btn-small" href="contact">Contact</a>
            </div>

            {/* Transisiotn sur height avec showMobile*/}
            <div className={`bdc-responsive ${(showMobile ? 'show' : '')}`} ref={mobileMenuRef}>
                    {links.map((link, index) => (
                        <a  key={index} 
                            href={link.url} 
                            onClick={toggleMobile}
                            className={`bdc-resp-item text-black/50 ${activeHash === link.url ? 'active' : ''}`}>
                            {link.title}
                        </a>
                    ))}
            </div>
        </div>
    );
}

export default Breadcrumb;