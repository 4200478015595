import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb, faHandshake } from "@fortawesome/free-regular-svg-icons";
import Workflow from "./Workflow";
import AOS from 'aos';

import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from "@gsap/react";

import 'aos/dist/aos.css';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const AboutView = () => {

    const images = [
        "images/about/about-agantar.webp",
        "images/about/about-code-03.webp",
        "images/about/about-notebook-01.webp",
        "images/about/about-code-02.webp",
        "images/about/about-ipad-01.webp",
        "images/about/about-code-01.webp",
    ]

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // const [currentFreelanceIndex, setCurrentFreelanceIndex] = useState(0);

    useEffect(() => {    
            document.title = "Agantar - À propos de moi..."

        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3500); // Change image every 3 seconds

        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true
        });


        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <>
        <div id="about" className="page">

        <h1 className='blue text-center mb-16 mt-2 sm:mb-8'>À propos d'Agantar...</h1>

            <div className="flex flex-col md:flex-row items-center justify-center gap-x-12 md:mb-20 mb-6 md:max-w-full max-w-[400px] mx-auto mt-16">
                <div data-aos="fade-right" className="md:w-[400px] mb-6 sm:p-0 px-4">
                    <p className="text-left text-lg mb-4">Agantar, c’est moi : <b className="blue">Gabriel CONRAIRIE</b>, infographiste et développeur web.</p>
                    <p className="text-lg mb-4">Depuis 2006, j’ai collaboré sur de nombreux projets pour des <b>associations</b>, des <b>particuliers</b> et des <b>entreprises</b>, aussi bien dans le secteur public que dans le privé.</p>
                    <p className="text-lg">De la création de sites vitrines et de boutiques e-commerce au développement d’applications mobiles et de plateformes de gestion sur mesure pour les PME, j’accompagne mes clients dans la concrétisation de leurs projets numériques.</p>
                </div> 

                <img data-aos="fade-left" src="images/about/gabriel.webp" alt="" className="rounded-2xl object-cover bg-agantar-blue/90"/>
            </div>



            

            <div className="md:mb-20 w-10/12 mx-auto">
                <h2 className="mb-8 text-center blue">Mes Valeurs</h2>

                <div data-aos="fade-up" className="card md:flex gap-8 items-center justify-start mb-8">
                        <div className="icon text-center w-48 shrink mb-6">
                            <FontAwesomeIcon icon={faHandshake} className="text-6xl text-agantar-light-blue"/>
                            <h4 className="blue">Loyauté</h4>
                        </div>
                        <div className="flex-1">
                            <p className="caption">Un échange en toute <b>confiance</b> repose sur la <b>loyauté</b> envers ses clients.</p>
                            <p className="mb-0">Je m’engage à respecter les <b>délais</b>, les <b>tarifs</b> et chaque élément défini ensemble pour bâtir votre présence numérique en toute sérénité.</p>
                        </div>
                </div>

                <div data-aos="fade-up" className="card md:flex gap-8 items-center justify-start mb-8">
                        <div className="icon text-center w-48 shrink mb-6">
                            <FontAwesomeIcon icon={faGlasses} className="text-6xl text-agantar-light-blue"/>
                            <h4 className="blue">Transparence</h4>
                        </div>
                        <div className="flex-1">
                            <p className="caption">Un professionnel doit être en mesure de vous <b>rendre des comptes</b> à tout moment.</p>
                            <p>Vous avez une question ? Je vous apporte des <b>réponses claires</b>, sans détour.</p>
                        </div>
                </div>

                <div data-aos="fade-up" className="card md:flex gap-8 items-center justify-start mb-8">
                        <div className="icon text-center w-48 shrink mb-6">
                            <FontAwesomeIcon icon={faLightbulb} className="text-6xl text-agantar-light-blue"/>
                            <h4 className="blue">Simplicité</h4>
                        </div>
                        <div className="flex-1">
                        <p className="caption">Un outil efficace doit être simple à utiliser.</p>
                        <p>Mon objectif : vous fournir un site <b>intuitif</b> et <b>accessible</b>, sans transformer votre outil de communication en casse-tête.</p>
                        </div>
                </div>
            </div>



            <div className="hidden xs:block xs:h-48 md:h-64 lg:h-[320px] relative overflow-hidden rounded-3xl md:mb-24 mb-6">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt=""
                        className={`absolute top-0 left-0 h-full w-full object-cover transition-opacity duration-1000 ${
                        index === currentImageIndex ? "opacity-100" : "opacity-0"
                    }`}
                    />
                ))}
                {/* Background image */}
                <img src="images/about/about-empty.webp" alt="" />
            </div>
            
        </div>





        <Workflow />
        </>
    );
}

export default AboutView;