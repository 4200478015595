import React, { useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import '../../assets/styles/layout/Navigation.css';

const Navigation = () => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const location = useLocation();

    const menu = [
        { name: "Accueil", path: "/" },
        { name: "Services", path: "/services" },
        { name: "À propos", path: "/about" },
        { name: "Contact", path: "/contact" },
        // { name: "Clients", path: "/customers" },
        // { name: "Blog", path: "/blog" }
    ]

    const indicatorRef = useRef<HTMLDivElement | null>(null);
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const hideMenu = () => {
        setMenuOpen(false);
    }

    const isActive = (path: string) => location.pathname === path ? "active" : "";

    useEffect(() => {
        const activeLink = document.querySelector(`.nav-container a.active`);
        const indicator = indicatorRef.current;

        const xPadding = 6;

        if (activeLink && indicator) {
            const { offsetLeft, offsetWidth } = activeLink as HTMLElement;
            indicator.style.left = `${offsetLeft - xPadding}px`;
            indicator.style.width = `${offsetWidth + (xPadding*2)}px`;
        }
    }, [location.pathname]);

    return (
        <header id="main-header">
            <div className="fixed top-0 left-0 right-0 z-[999]
                bg-white/80 dark:bg-agantar-dark-bg/20 
                backdrop-blur-lg 
                backdrop-filter
                z-[999]">

                <div className="page relative">
                    <nav className="px-4">
                        <div className="flex items-center justify-between">
                            {/* LOGO */}
                            <a href="/" className="logo py-1">
                                <Link to="/">
                                <Logo className="w-24 fill-agantar-blue dark:fill-white"/>
                                </Link>
                            </a>

                            {/* RIGHT MENU */}
                            {/* Desktop */}
                            <div className="nav relative hidden md:flex nav-container gap-x-8 py-3 rounded-xl items-center justify-center">
                                { menu.map((item, index) => (
                                    <Link to={item.path} key={index} className={isActive(item.path)}>{item.name}</Link>
                                ))}
                                {/* Barre indicateur */}
                                <div 
                                ref={indicatorRef}
                                className="absolute bottom-1 left-0 w-0 h-[1px] bg-agantar-blue dark:bg-agantar-light-blue transition-all duration-300 rounded"
                                ></div>
                            </div>

                            {/* Mobile */}
                            <div className="right-menu-group flex gap-x-4 items-center md:hidden">
                                    {/* <button className={`burger text-lg transition-transform ${ menuOpen && 'rotate'}`} onClick={handleResponsiveMenu}><FontAwesomeIcon icon={faBars} /></button> */}
                                    <button
                                        className="p-4 focus:outline-none md:hidden text-agantar-blue dark:text-white"
                                        onClick={toggleMenu}
                                        >
                                        <FontAwesomeIcon icon={faBars}/>
                                    </button>
                                </div>
                            </div>
                    </nav>
                </div>
            </div>     


                <div className={`fixed w-full top-0 left-0 z-[99] transition duration-300  ${ menuOpen ? 'translate-y-0' : '-translate-y-96' }`}>
                    <div className="pt-20 pb-12 flex flex-col space-y-6 items-center justify-between w-[98%] mx-auto bg-white dark:bg-gray-900/80 
                            backdrop-blur-lg 
                            backdrop-filter 
                            rounded-b-lg shadow-lg">
                        {menu.map((item, index) => (
                            <Link to={item.path} key={index} className="subheading uppercase" onClick={hideMenu}>{item.name}</Link>
                        ))}
                    </div>
                </div>

                {/* RESPONSIVE MENU GROUP */}
                {/* <div className={`md:hidden fixed transform top-16 right-0 ${ menuOpen ? 'translate-x-0' : 'translate-x-64' } transition-transform duration-300 ease-in-out text-center`}>
                        <div className="flex flex-col">
                            <Link to="/" onClick={hideMenu} className="responsive-menu-item">
                                <FontAwesomeIcon icon={faHome} className="pr-2"/>
                                Accueil
                            </Link>
                            <Link to="/contact" onClick={hideMenu} className="responsive-menu-item">
                                <FontAwesomeIcon icon={faEnvelope} className="pr-2"/>
                                Contact
                            </Link>
                        </div>                
                </div> */}
        </header>  
    );
}

export default Navigation;