import React, { useRef } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useGSAP } from "@gsap/react";
import WorkflowCard from './components/WorkflowCard';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Workflow = () => {

    const mainRef = useRef<HTMLDivElement>(null);    
    const step1Ref = useRef(null);
    const step2Ref = useRef(null);
    const step3Ref = useRef(null);
    const step4Ref = useRef(null);
    const step5Ref = useRef(null);
    const step6Ref = useRef(null);
    const step7Ref = useRef(null);
    
    const icon1Ref = useRef(null);
    const icon2Ref = useRef(null);
    const icon3Ref = useRef(null);
    const icon4Ref = useRef(null);
    const icon5Ref = useRef(null);
    const icon6Ref = useRef(null);
    const icon7Ref = useRef(null);

    const iconRefs = [icon1Ref, icon2Ref, icon3Ref, icon4Ref, icon5Ref, icon6Ref, icon7Ref];
    const stepRefs = [step1Ref, step2Ref, step3Ref, step4Ref, step5Ref, step6Ref, step7Ref];

    const dotLineRef = useRef(null);

    useGSAP(
        () => { 
            if(!mainRef.current) return;
            const containerHeight = mainRef.current.clientHeight;

            gsap.fromTo(
                dotLineRef.current,
                { height: 0 },
                {
                    height: containerHeight,
                    ease: "none",
                    scrollTrigger: {
                        trigger: mainRef.current,
                        id: "dot line",
                        start: "top 65%",
                        end: `${containerHeight}px 75%`, 
                        toggleActions: "none none none none",
                        scrub: 1
                    }
                }
            )

            // ICONS
            iconRefs.forEach((iconRef, index) => {
                if (!iconRef.current) return; // Ensure ref is valid
        
                gsap.fromTo(
                    iconRef.current,
                    { opacity: 0,scale: 0.1 },
                    {
                        opacity: 1,
                        scale: 1,
                        ease: "none",
                        duration: .1,
                        scrollTrigger: {
                            id: `icon-${index + 1}`,
                            start: "top 70%",
                            end: "top 70%",
                            trigger: iconRef.current,
                            scrub: 1,
                            toggleActions: "none none none none"
                        }
                    }
                );
            });

            // Workflows
            stepRefs.forEach((stepRef, index) => {
                if (!stepRef.current) return; // Ensure ref is valid
        
                gsap.fromTo(
                    stepRef.current,
                    { scale: 0.1, opacity: 0 },
                    {
                        scale: 1, opacity: 1,
                        ease: "none",
                        scrollTrigger: {
                            id: `step-${index + 1}`,
                            start: "top 70%",
                            end: "top 70%",
                            trigger: stepRef.current,
                            scrub: 1,
                            toggleActions: "none none none none"
                        }
                    }
                );
            }
            );
    
        }, { scope: mainRef});

        const iconClass = "w-fit bg-gray-50 dark:bg-gray-700 rounded-full text-5xl p-5 mx-auto mb-16";


    return (
        <div className="bg-agantar-blue py-16">
            <div className="page text-center">
                <h2 className="mb-8 text-white">Méthodologie de travail</h2>

                <div className="worflow relative" ref={mainRef}>

                    <div 
                        ref={dotLineRef} 
                        className="absolute w-0 border-white border-r-4 border-dotted left-0 right-0 mx-auto"
                    ></div>

                    <div ref={icon1Ref} className={iconClass}>💡</div>
                    <WorkflowCard 
                        ref={step1Ref} 
                        title="1. Échange & Analyse" 
                        description="Tout commence par une discussion pour comprendre vos besoins, vos attentes et vos objectifs. Nous définissons ensemble la meilleure approche pour donner vie à votre projet."
                        />

                    <div ref={icon2Ref} className={iconClass}>📝</div>
                    <WorkflowCard 
                        ref={step2Ref} 
                        title="2. Proposition & Devis" 
                        description="Une fois vos attentes clarifiées, je vous propose une solution adaptée avec un devis détaillé. Ce document précise les fonctionnalités, les délais et le budget."
                        />

                    <div ref={icon3Ref} className={iconClass}>🖍️</div>
                    <WorkflowCard 
                        ref={step3Ref} 
                        title="3. Conception & Design" 
                        description="Avant de coder, je travaille sur la structure du site (wireframes, maquettes si besoin). L’objectif : garantir une ergonomie intuitive et un design qui vous correspond."
                        />

                    <div ref={icon4Ref} className={iconClass}>💻</div>
                    <WorkflowCard 
                        ref={step4Ref} 
                        title="4. Développement" 
                        description="Place à la technique ! J’intègre vos contenus, développe les fonctionnalités et optimise chaque élément pour un site rapide, fluide et responsive."
                        />

                    <div ref={icon5Ref} className={iconClass}>🔍</div>
                    <WorkflowCard 
                        ref={step5Ref} 
                        title="5. Tests & Ajustements" 
                        description="Avant la mise en ligne, des tests sont effectués pour s’assurer que tout fonctionne parfaitement : compatibilité mobile, rapidité, sécurité… Des ajustements sont faits si nécessaire."
                        />

                    <div ref={icon6Ref} className={iconClass}>🚀</div>
                    <WorkflowCard 
                        ref={step6Ref} 
                        title="6. Mise en ligne & Formation" 
                        description="Une fois validé, votre site est mis en ligne. Je vous accompagne pour la prise en main afin que vous puissiez le gérer en toute autonomie."
                        
                        />

                    <div ref={icon7Ref} className={iconClass}>⚙️</div>
                    <WorkflowCard 
                        ref={step7Ref} 
                        title="7. Suivi & Maintenance*" 
                        description="Votre site est en ligne, mais je reste disponible pour assurer la maintenance, effectuer des mises à jour et garantir sa sécurité et ses performances. (optionnel)"
                        />

                </div>

            </div>
        </div>
    );
    }

export default Workflow;    