import Breadcrumb from "./Breadcrumb";

const LayoutView = () => {
    return (
        <>
            <Breadcrumb 
            title="Layout"
            links={[
                { title: "Accueil", url: "/" },
            ]}/>

            <div className="page last">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Est, voluptatum provident eveniet adipisci consectetur eaque necessitatibus labore suscipit sint dolorem sunt, unde in et voluptate neque libero laboriosam doloribus deleniti?
            Eligendi asperiores, esse velit molestiae quis natus eaque ad est consectetur illo nostrum minima voluptate reiciendis, repellat vitae vero omnis mollitia! Modi adipisci aliquam impedit facilis officiis repellendus minus earum!
            </div>
        </>
    );
    }

export default LayoutView;