import { faWordpress } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AOS from "aos"
import "aos/dist/aos.css"

const DevelopmentView = ({ className } : { className?: string}) => {
    AOS.init();
    return (
        <div id="development-service" className={className}>
            <div className="responsive-header">
                <h3 className="text-4xl font-bold mb-4">Développement Avancé</h3>   
                <p className="mb-8">Agantar <b>répare, modernise et protège</b> votre site web pour garantir des performances optimales et une sécurité renforcée.</p>  
            </div>
        
            <div className="responsive-grid">
    
                <div data-aos="fade-up" className="responsive-card">
                    <h4 className="text-2xl -mb-1">Plugin Wordpress <FontAwesomeIcon icon={faWordpress} className="text-blue-500"/></h4>
                    <p className="text-xl font-light ">À partir de <b className="font-semibold">250€</b> <span className="text-sm">TTC</span></p>

                    <p className="pt-4 pb-6 semibold">
                    Boostez les fonctionnalités de votre site WordPress grâce à des <b>plugins sur-mesure</b>, adaptés à vos besoins spécifiques.
                    </p>                    
                    <a href="/contact?q=pwo" className="btn p-2 w-full text-center primary">Demander un devis</a>
                </div>


                <div data-aos="fade-up" className="responsive-card">
                    <h4 className="text-2xl -mb-1">Progressive Web App 📱</h4>
                    <p className="text-xl font-light ">À partir de <b className="font-semibold">999€</b> <span className="text-sm">TTC</span></p>
                    <p className="pt-4 pb-6 semibold">
                    Transformez votre site en une <b>application mobile progressive</b> performante, accessible sur tous les appareils, sans téléchargement.
                    </p>                    
                    <a href="/contact?q=pwa" className="btn p-2 w-full text-center primary">Demander un devis</a>
                </div>
                

                <div data-aos="fade-up" className="responsive-card">
                    <h4 className="text-2xl -mb-1">Développement spécifique 🛠️</h4>
                    <p className="text-xl font-light ">Sur devis exclusivement</p>
                    <p className="pt-4 pb-6 semibold">
                    Créez des solutions web uniques pour répondre aux exigences spécifiques de votre projet.
                    </p>
                    <a href="/contact?q=dsm" className="btn p-2 w-full text-center primary">Demander un devis</a>
                </div>
            </div>        
        </div>
    )
}

export default DevelopmentView