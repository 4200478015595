import React from 'react';

const LegalNoticeView = () => {
  const date = '11/02/2025';
  return (
    <div className="legal-notice-container page">
      <h1 className='blue text-center'>Mentions Légales</h1>
      
      <section>
        <h3 className="pt-12 pb-3">1. Éditeur du site</h3>
        <p><b>Nom de l’entreprise :</b> Agantar</p>
        <p><b>Responsable de publication :</b> Gabriel CONRAIRIE</p>
        <p><b>Adresse :</b> 5 rue du Vinarium 11170 VILLESÈQUELANDE</p>
        <p><b>Email :</b> <a href="mailto:contact@agantar.com" className='link'>contact@agantar.com</a></p>
        <p><b>Téléphone :</b> +33(0)6.58.00.87.65</p>
        <p><b>Numéro SIRET :</b> 490830775</p>
      </section>
      
      <section>
        <h3 className="pt-12 pb-3">2. Hébergement</h3>
        <p><b>Hébergeur :</b> OVH</p>
        <p><b>Adresse :</b> 2 Rue Kellermann, 59100 Roubaix, France</p>
        <p><b>Site Web :</b> <a href="https://www.ovh.com" className='link'>www.ovh.com</a></p>
      </section>
      
      <section>
        <h3 className="pt-12 pb-3">3. Protection des données personnelles</h3>
        <p>Les informations collectées via le site sont utilisées uniquement pour répondre aux demandes des utilisateurs et améliorer l'expérience.</p>
        <p>Consultez notre <a href="/privacy-policy">Politique de Confidentialité</a> pour en savoir plus sur la gestion de vos données.</p>
      </section>
      
      <section>
        <h3 className="pt-12 pb-3">4. Propriété intellectuelle</h3>
        <p>Tout le contenu du site (textes, images, logos, etc.) est protégé par les lois sur la propriété intellectuelle. Toute reproduction est interdite sans autorisation préalable.</p>
      </section>
      
      <section>
        <h3 className="pt-12 pb-3">5. Responsabilité</h3>
        <p>Les liens externes présents sur le site n'engagent pas la responsabilité de Agantar quant à leur contenu.</p>
      </section>
      
      <section>
        <h3 className="pt-12 pb-3">6. Droit applicable</h3>
        <p>Le site et son contenu sont régis par le droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
      </section>
      
      <p className="py-12 text-sm">Dernière mise à jour : {date}</p>
    </div>
  );
};

export default LegalNoticeView;
