
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { faInstagram, faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="w-full">  
            <div className="bg-gray-50 dark:bg-black/20 lg:px-4">
                <div className="py-12 page">   

                    <div className="flex flex-col lg:flex-row lg:items-start justify-between">
                        {/*  CONTACT BLOC */}
                        <div className="flex flex-col space-y-6 mb-6  lg:mb-0 items-center lg:items-start lg:text-left text-center">

                            {/* LOGO */}
                            <a href="/">
                            <Logo className="fill-agantar-blue dark:fill-white w-48"/>
                            </a>


                            {/* CONTACT INFOS */}
                            <ul className='m-0 p-0'>
                                <li className='list-none m-0 p-0'>
                                    <a href="tel:06.58.00.87.65" className='hover:text-agantar-light-blue'>
                                        <FontAwesomeIcon icon={faPhone} className='pr-2'/> 
                                        06.58.00.87.65</a></li>
                                <li className='list-none m-0 p-0'>
                                    <a href="mailto:contact@agantar.com" className='hover:text-agantar-light-blue'>
                                        <FontAwesomeIcon icon={faEnvelope} className='pr-2'/> 
                                        contact@agantar.com</a></li>
                            </ul>


                            {/* SOCIAL NETWORKS */}
                            <div className="flex space-x-4">
                                <a href="https://www.linkedin.com/company/agantar/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} className="text-3xl hover:text-agantar-light-blue"/>
                                </a>
                                <a href="https://www.instagram.com/agantar_officiel/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} className="text-3xl hover:text-agantar-light-blue"/>
                                </a>
                                <a href="https://www.facebook.com/agantar.officiel/" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faFacebook} className="text-3xl hover:text-agantar-light-blue"/>
                                </a>
                            </div>
                        </div>



                        {/*  FOOTER MENU */}
                        <div className="flex flex-col md:flex-row md:items-start flex-wrap items-center justify-center text-sm text-center gap-6">
                            <div className="footer-menu-bloc">
                                <div className="heading uppercase text-black/70 dark:text-white">SITE WEB</div>
                                <ul className='clear'>
                                    <li className="underline pb-2">
                                        <Link to="/site-vitrine">
                                            Site Vitrine
                                        </Link>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#site-service">
                                            Site e-commerce
                                        </a>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#site-service">
                                            Application Web
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div className="footer-menu-bloc">
                                <div className="heading uppercase text-black/70 dark:text-white">Dépannage</div>
                                <ul className='clear'>
                                    <li className="underline pb-2">
                                        <a href="services#repair-service">
                                            Refonte site web
                                        </a>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#repair-service">
                                            Dépannage et débuggage
                                        </a>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#repair-service">
                                            Sécurisation
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-menu-bloc">
                                <div className="heading uppercase text-black/70 dark:text-white">Développement</div>
                                <ul className='clear'>
                                    <li className="underline pb-2">
                                        <a href="services#development-service">
                                            Plugin Wordpress
                                        </a>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#development-service">
                                            Progressive Web App
                                        </a>
                                    </li>
                                    <li className="underline pb-2">
                                        <a href="services#development-service">
                                            Développement sur-mesure
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-menu-bloc">
                                <div className="heading uppercase text-black/70 dark:text-white">DIVERS</div>
                                <ul className='clear'>
                                    <li className="underline pb-2">
                                        <a href="services#top">Services</a>
                                    </li>
                                    <li className="underline pb-2">
                                        <Link to="/about" className="underline">À propos</Link>
                                    </li>
                                    <li className="underline pb-2">
                                        <Link to="/contact" className="underline">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div className="flex text-sm gap-3 justify-center py-6 flex-wrap">
                    <Link to="/politique-confidentialite" className="underline">Politique de confidentialité</Link>
                    <Link to="/mentions-legales" className="underline">Mentions légales</Link>
                    <Link to="/sitemap.xml" className="underline">Sitemap</Link>
                </div>




            </div>

            <div className="bg-gray-100 dark:bg-black/30 py-2 text-black/40 dark:text-white/80 text-[10px]">
                <div className="page text-center">
                Agantar ©2025 - Tous droits réservés
                </div>
            </div>
        </footer>
    );
}

export default Footer;