import React, {useEffect, useState} from 'react';
import Compare from './components/Compare';
import CompareTitle from './components/CompareTitle';
import PriceBlock from './components/PriceBlock';
import '../../assets/styles/pages/Sites.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Benefits from './components/Benefits';
import { faWordpress } from '@fortawesome/free-brands-svg-icons';

const VitrineView = () => {
    const [monthlyFee, setMonthlyFee] = useState(false);
    const formules= [
        { 
            total: 599,
            discount: 499,
            monthly: 45,
        },
        { 
            total: 1199,
            monthly: 108,
        },
        { 
            total: 1999,
            monthly: 180,
        }
    ]

    useEffect(() => {   
        document.title = "Agantar - Site vitrine"
    }, [])

    return (
        <>
            <div className="page">

                <h1 className="text-center blue">Site vitrine</h1>

                <p className="middle">
                Le site vitrine est <b>la solution idéale pour présenter votre activité</b>
                , informer vos clients et renforcer votre crédibilité en ligne. Il est conçu pour être simple, intuitif et performant.
                </p>    



                <div className="payment mb-16">
                    <h3 className='text-2xl font-bold text-center'>Choisissez votre mode de paiement</h3>
                    <p className='text-center mb-6'>Réduisez vos frais en choisissant le paiement unique !</p>
                    
                    <div className="flex sm:flex-row flex-col w-fit mx-auto">
                        <button 
                            className={`w-48 text-center px-5 py-3 transition sm:rounded-tl-xl sm:rounded-bl-xl rounded-t-xl sm:rounded-tr-none ${ monthlyFee ? 'bg-agantar-light-blue text-white shadow-lg' : 'bg-gray-200 dark:text-agantar-text ' }`}
                            onClick={() => setMonthlyFee(true)} >
                            Paiement mensuel</button>
                        <button 
                            className={`w-48 text-center px-5 py-3 transition sm:rounded-r-xl rounded-b-xl sm:rounded-bl-none ${ monthlyFee ? 'bg-gray-200 dark:text-agantar-text ' : 'bg-agantar-light-blue text-white shadow-lg' }`}
                            onClick={() => setMonthlyFee(false)} >
                            Paiement unique</button>
                    </div>
                </div>      



                {/* grid lg:grid-cols-3 md:grid-cols-2 */}
                <div className="flex gap-6 mb-24 md:max-w-full max-w-[320px] mx-auto flex-wrap justify-center">     

                    <div className="sm:flex-[0_0_400px] flex-1">
                        <div className="lg:block hidden h-10 "></div>
                        <div className="formule offer rounded-xl bg-gray-100 dark:bg-gray-700/80  p-6">
                            <p className="text-3xl font-bold">Site One-Page 🚀</p>                        
                            <PriceBlock data={formules[0]} monthlyFee={monthlyFee}/>
                            <p className='mb-3'>Un site <span className="semibold">simple et impactant</span> pour être visible en ligne immédiatement.</p>
                            <div className="benefits my-6">
                                <p className="bg-gray-200 dark:bg-black/30 text-center p-1 rounded-t-xl">Les <FontAwesomeIcon icon={faCirclePlus} className="text-agantar-blue dark:text-agantar-light-blue"/> de la formule</p>
                                {/* Avantages */}
                                <div className="bg-white dark:bg-black/20 rounded-b-xl overflow-hidden">

                                    <Benefits 
                                        title="🎯 Une page unique optimisée" 
                                        description="Présentez l’essentiel de votre activité en une page impactante" />

                                    <Benefits
                                        title="🔑 Site clé en main"
                                        description="Je m’occupe de tout, de la création au déploiement, pour que vous puissiez vous concentrer sur votre activité." />

                                    <Benefits 
                                        title="📱 Adapté à tous les écrans" 
                                        description="Un site qui s’affiche parfaitement sur mobile, tablette et ordinateur." />

                                    <Benefits
                                        title="🚀 Mise en ligne rapide"
                                        description="Votre site est en ligne en seulement quelques jours." 
                                        last/>
                                </div>
                            </div>
                            <a href="/contact?q=so" className="btn px-3 py-2 text-center w-fit mx-auto mt-6 primary">Commencez avec l’essentiel</a>

                        </div>
                    </div>

                    <div className="sm:flex-[0_0_400px] flex-1">
                    <div className="lg:block hidden h-10 "></div>
                        <div className="formule rounded-xl bg-gray-100 dark:bg-gray-700/80 p-6">
                            <p className="text-3xl font-bold">Site Vitrine Essentiel ⭐️</p>
                            <PriceBlock data={formules[1]} monthlyFee={monthlyFee} />
                            <p>Un site <span className="semibold">complet et professionnel</span> pour informer, séduire et convaincre vos clients.</p>
                            {/* Avantages */}
                            <div className="benefits my-6">
                                <p className="bg-gray-200 dark:bg-black/30 text-center p-1 rounded-t-xl">Les <FontAwesomeIcon icon={faCirclePlus} className="text-agantar-blue dark:text-agantar-light-blue"/> de la formule</p>
                                {/* Avantages */}
                                <div className="bg-white dark:bg-black/20 rounded-b-xl overflow-hidden">

                                    <Benefits 
                                        title="⭐️ Jusqu’à 5 pages personnalisées" 
                                        description="Présentez en détail vos services, produits, valeurs, et bien plus." />

                                    <Benefits
                                        title="🔑 Site clé en main"
                                        description="Je m’occupe de tout, de la création au déploiement, pour que vous puissiez vous concentrer sur votre activité." />

                                    <Benefits 
                                        title="📱 Adapté à tous les écrans" 
                                        description="Un site qui s’affiche parfaitement sur mobile, tablette et ordinateur." />

                                    <Benefits
                                        title="🔎 Référencement optimisé (SEO)"
                                        description="Améliorez votre visibilité en ligne avec des balises, titres et descriptions adaptés à chaque page." 
                                        last />
                                </div>
                            </div>



                            <a href="/contact?q=se" className="btn px-3 py-2 text-center w-fit mx-auto mt-6 primary">Construisez votre présence</a>
                        </div>
                    </div>


                    <div className="sm:flex-[0_0_400px] flex-1">
                        <div className="h-10 bg-agantar-light-blue text-center text-white py-2 rounded-t-xl border-agantar-light-blue border-2">
                            Meilleure Option
                        </div>
                        <div className="formule rounded-b-xl bg-gray-100 dark:bg-gray-700/80 p-6 border-agantar-light-blue border-2">
                            <p className="text-3xl font-bold">Site vitrine Pro 🎯</p>                            
                            <PriceBlock data={formules[2]}  monthlyFee={monthlyFee} />
                            <p>La formule idéale pour une <b className="semibold">présence en ligne sans compromis</b>.</p>
                            {/* Avantages */}
                            <div className="benefits my-6">
                                <p className="bg-gray-200 dark:bg-black/30 text-center p-1 rounded-t-xl">Les <FontAwesomeIcon icon={faCirclePlus} className="text-agantar-blue dark:text-agantar-light-blue"/> de la formule</p>
                                {/* Avantages */}
                                <div className="bg-white dark:bg-black/20 rounded-b-xl overflow-hidden">

                                    <Benefits 
                                        title="👍 Nombre de pages illimité" 
                                        description="Concevez un site aussi riche et détaillé que nécessaire, sans limite." />

                                    <Benefits 
                                        title="Autonomie complète" 
                                        icon={faWordpress}
                                        description="Gérez votre contenu en toute liberté avec un accès complet à l’administration grâce à Wordpress." />

                                    <Benefits
                                        title="🛠️ Plugins personnalisés inclus"
                                        description="Intégrez des fonctionnalités sur-mesure pour répondre aux besoins spécifiques de votre activité." />

                                    <Benefits
                                        title="🔎 Référencement avancé (SEO)"
                                        description="Boostez votre visibilité avec un plugin SEO complet et des options d’optimisation poussées." />

                                    <Benefits
                                        title="📓 Formation"
                                        description="Devenez maître de votre site avec une formation pour gérer l’administration et les contenus."
                                        last />
                                </div>
                            </div>
                            <a href="/contact?q=sp" className="btn px-3 py-2 text-center w-fit mx-auto mt-6 primary">Passez à la vitesse supérieure</a>
                        </div>
                    </div>
                </div>











            
                <h3 className='text-2xl font-bold text-center'>Comparer les formules</h3>
                <p className='text-center mb-4'>Choisissez le forfait qui correspond le mieux à vos besoins.</p>

                <div className="flex sm:flex-row flex-col w-fit mx-auto text-black/30 text-sm my-8">
                    <button 
                        className={`w-40 text-center px-2 py-2 transition 
                            rounded-t-xl sm:rounded-l-xl sm:rounded-tr-none 
                            ${ monthlyFee ? 'bg-agantar-light-blue text-white shadow-lg' : 'bg-gray-200 dark:text-agantar-text' }`}
                        onClick={() => setMonthlyFee(true)} >
                        Paiement mensuel</button>
                    <button 
                        className={`w-40 text-center px-2 py-2 transition 
                            rounded-b-xl sm:rounded-r-xl sm:rounded-bl-none
                            ${ monthlyFee ? 'bg-gray-200 dark:text-agantar-text' : 'bg-agantar-light-blue text-white shadow-lg' }`}
                        onClick={() => setMonthlyFee(false)} >
                        Paiement unique</button>
                </div>









            <div className="compare lg:overflow-hidden overflow-x-auto w-full">
                <div className="min-w-[800px] w-full mx-auto lg:overflow-hidden overflow-scroll">
                    
                    <div className="flex w-full">
                        <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-2"></div>
                        <div className="flex flex-1"></div>
                        <div className="flex flex-1"></div>
                        <div className="flex flex-1 border-2 border-agantar-light-blue border-b-0 border-r-0 rounded-t-xl bg-agantar-light-blue">
                            <p className="py-1 w-full text-white rounded-t-xl text-center ">
                            Meilleure Option
                            </p>
                        </div>
                    </div>


                    <div className="flex w-full">
                        <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-2"></div>
                        <div className="flex flex-1">
                            <div className="flex-1 p-2 text-center bg-gray-50 dark:bg-gray-700/80 border-2 border-r-0 rounded-tl-xl ">
                                <p>Site One-Page</p>
                                <PriceBlock data={formules[0]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=so" className="btn btn thin w-fit mx-auto my-2 secondary">Lancer mon projet</a>                          
                            </div>
                            <div className="flex-1 p-2 text-center bg-gray-50 dark:bg-gray-700/80 border-2 border-r-0">
                                <p>Site Vitrine Essentiel</p>
                                <PriceBlock data={formules[1]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=se" className="btn btn thin w-fit mx-auto my-2 secondary">Lancer mon projet</a>
                            </div>
                            <div className="flex-1 p-2 text-center bg-blue-500/10 border-2 border-x-2 border-agantar-light-blue">
                                <p>Site Vitrine Pro</p>
                                <PriceBlock data={formules[2]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=sp" className="btn btn thin w-fit mx-auto my-2 primary">Lancer mon projet</a>
                            </div>
                        </div>
                    </div>

                    <CompareTitle title="Hébergement" size={formules.length} best={3} />

                    <Compare title="Hébergement (1 an)" values={[true, true, true]}>
                        Hébergement sécurisé sur serveur OVH localisé en France (12 mois inclus).”
                    </Compare>

                    <Compare title="Nom de domaine (1 an)" values={[true, true, true]}>
                        Nom de domaine (.fr) offert la première année. Autres extensions disponibles sur demande.
                    </Compare>

                    <Compare title="Certificat SSL" values={[true, true, true]} last>
                        Sécurisation de votre site avec un certificat SSL (https).
                    </Compare>


                        <CompareTitle title="Options" size={formules.length} best={3}  />

                    <Compare title="Site responsive" values={[true, true, true]}>
                        Votre site s'adapte à tous les écrans : ordinateurs, tablettes et smartphones.
                    </Compare>

                    <Compare title="Formulaire de contact" values={[true, true, true]}>
                        Intégration d'un formulaire de contact afin de faciliter les échanges avec vos clients.
                    </Compare>

                    <Compare title="Conformité RGPD" values={[true, true, true]}>
                        Gestion conforme des cookies et des données personnelles.
                    </Compare>

                    <Compare title="Nombre de pages" values={['1', '5', 'Illimitées']}>
                        Le nombre maximal de pages inclus dans l'abonnement.
                    </Compare>

                    <Compare title="Propositions de designs personnalisé" values={['1', '2', '3']}>
                        Nombre de concepts graphiques proposés pour la mise en page et l’apparence générale de votre site. Chaque proposition est adaptée à vos besoins et feedbacks.
                    </Compare>
                    
                    <Compare title="Référencement (SEO)" values={['Basique', 'Optimisé', 'Avancé (Plugin SEO)']}>  
                        <ul>
                            <li>
                                <u>Basique</u> : Référencement automatique de la page unique, sans contrôle manuel.
                            </li>
                            <li>
                                <u>Optimisé</u> : Optimisation des balises, titres et descriptions page par page.
                            </li>
                            <li>
                                <u>Avancé</u> : Gestion complète via un <b className='font-semibold'>plugin SEO</b>, permettant de modifier <b className="font-semibold">balises meta, titres et descriptions</b> à volonté.
                            </li>
                        </ul>
                    </Compare>

                    <Compare title="Administration" values={['Déléguée', 'Partagée', 'Autonome']}>
                        <ul>
                            <li><u>Déléguée</u> : Je m'occupe de tout.</li>
                            <li><u>Partagée</u> : Accès simplifié à l’administration pour modifier le contenu de vos pages, avec un accompagnement technique pour les ajustements complexes..</li>
                            <li><u>Autonome</u> : Vous avez accès à l'administration du site pour le modifier à volonté et sans limitations.</li>
                        </ul>
                    </Compare>

                    <Compare title="Statistiques" values={[false, true, true]}>
                        Intégration de Google Analytics pour suivre les performances de votre site.
                    </Compare>

                    <Compare title="Intégration de Plugins" values={[false, false, true]}>
                        Intégration de fonctionnalités supplémentaires à votre site.
                    </Compare>

                    <Compare title="Formation à l'administration" values={[false, false, true]} last>
                        Une formation vous est dispensée pour maîtriser l'administration de votre site.
                    </Compare>

                    <CompareTitle title='Support' size={formules.length} best={3}  />
                    
                    <Compare title="Support technique" values={['1 mois', '3 mois', '6 mois']}>
                        Accompagnement personnalisé après la livraison pour gérer votre contenu, installer une nouvelle fonctionnalité ou obtenir des conseils.
                    </Compare>

                    <Compare title="Maintenance" values={['Basique', 'Optimisée', 'Complète']}>
                        Assure le bon fonctionnement du site grâce à des mises à jour régulières et des actions préventives.
                        <ul>
                            <li>
                                <u>Basique</u> : Mises à jour du site.
                            </li>
                            <li>
                                <u>Optimisée</u> : Mises à jour régulières et sauvegardes planifiées.
                            </li>
                            <li>
                                <u>Complète</u> : Mises à jour régulières, gestion des plugins et sauvegardes avancées.
                            </li>
                        </ul>
                    </Compare>

                    <Compare title='Garantie de fonctionnement' values={[true, true, true]} last>
                    Tous nos sites bénéficient d’une garantie de 12 mois pour corriger gratuitement tout bug ou dysfonctionnement.
                    </Compare>

                    <div className="flex w-full">
                        <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-2"></div>
                        <div className="flex flex-1">
                            <div className="flex-1 p-2 text-center bg-gray-50 dark:bg-gray-700/80 border-b-2 border-l-2 rounded-bl-xl ">
                                <p>Site One-Page</p>
                                <PriceBlock data={formules[0]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=so" className="btn btn thin w-fit mx-auto my-2 secondary">Lancer mon projet</a>                          
                            </div>
                            <div className="flex-1 p-2 text-center bg-gray-50 dark:bg-gray-700/80 border-b-2 border-l-2">
                                <p>Site Vitrine Essentiel</p>
                                <PriceBlock data={formules[1]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=se" className="btn btn thin w-fit mx-auto my-2 secondary">Lancer mon projet</a>
                            </div>

                            <div className="flex-1 p-2 text-center bg-blue-500/10 border-agantar-light-blue border-b-2 border-x-2">
                                <p>Site Vitrine Pro</p>
                                <PriceBlock data={formules[2]} monthlyFee={ monthlyFee } compared />
                                <a href="/contact?q=sp" className="btn btn thin w-fit mx-auto my-2 primary">Lancer mon projet</a>
                            </div>
                        </div>
                    </div>


                    <div className="flex w-full">
                        <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-2"></div>
                        <div className="flex flex-1"></div>
                        <div className="flex flex-1"></div>
                        <div className="flex flex-1 border-2 border-agantar-light-blue border-t-0 border-r-0 rounded-b-xl bg-agantar-light-blue">
                            <p className="py-1 w-full text-white text-center ">
                            Meilleure Option
                            </p>
                        </div>
                    </div>
                
                </div>
            </div>


            <div className="flex sm:flex-row flex-col w-fit mx-auto text-black/30 text-sm my-8">
                <button 
                    className={`w-40 text-center px-2 py-2 transition 
                        rounded-t-xl sm:rounded-l-xl sm:rounded-tr-none 
                        ${ monthlyFee ? 'bg-agantar-light-blue text-white shadow-lg' : 'bg-gray-200 dark:text-agantar-text' }`}
                    onClick={() => setMonthlyFee(true)} >
                    Paiement mensuel</button>
                <button 
                    className={`w-40 text-center px-2 py-2 transition 
                        rounded-b-xl sm:rounded-r-xl sm:rounded-bl-none
                        ${ monthlyFee ? 'bg-gray-200 dark:text-agantar-text' : 'bg-agantar-light-blue text-white shadow-lg' }`}
                    onClick={() => setMonthlyFee(false)} >
                    Paiement unique</button>
            </div>












                



            <caption className='py-3 mt-10'>*L’abonnement mensuel est souscrit pour une durée minimale de 12 mois.</caption>
            </div>

        </>
    )
    }

export default VitrineView;