
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faCreditCard, faDna, faGear, faKey, faPersonChalkboard, faRocket, faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";

const SitesView = ({ className } : { className?: string}) => {
	AOS.init();
    return (
        <div id="site-service" className={className}>
				<div className="responsive-header">
					<h3 className="text-4xl font-bold mb-4">Nos solutions de création de sites Web</h3>   
					<p className="mb-12">Que vous souhaitiez un  <b>site vitrine simple et efficace</b>, une <b>boutique e-commerce performante</b> ou une <b>solution sur-mesure pour un projet complexe</b>, Agantar vous accompagne à chaque étape. Explorez nos offres et trouvez la formule qui correspond à vos besoins.</p>
				</div>
                
            <div className="responsive-grid">
				<div data-aos="fade-up" className="responsive-card overflow-hidden !p-0">
					<img src="images/services/option-vitrine.webp" alt="" className="object-cover"/>
					<div className="p-6">
						<h3 className="font-bold text-3xl mb-1">Site Vitrine 🚀</h3>
						<p className="text-xl font-light">À partir de <b className="font-semibold">499€</b> <span className="text-sm">TTC</span></p>
						
						<p className="pt-4 pb-6 semibold">Parfait pour <b>établir une présence en ligne rapidement</b>.</p>

                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faRocket} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Mise en ligne rapide</p>
							</div>
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faKey} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Livraison clé en main</p>
							</div>
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faShieldHalved} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Hébergement sécurisé</p>
							</div>
						</div>
						
						<a href="/site-vitrine" className="btn p-2 w-full text-center primary">Commencez avec un site vitrine</a>
					</div>
				</div>

				<div data-aos="fade-up" className="responsive-card overflow-hidden !p-0">
					<img src="images/services/option-e-commerce.webp" alt="" className="object-cover"/>
					<div className="p-6">
						<h3 className="font-bold text-3xl mb-1">Site e-commerce 🛒</h3>
						<p className="text-xl font-light">À partir de <b>2999€</b>TTC</p>
						<p className="pt-4 pb-6">Idéal pour <b>vendre vos produits ou services 24/7</b>.</p>

                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faCreditCard} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Paiements en ligne sécurisés</p>
							</div>

                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faShieldHalved} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Hébergement sécurisé</p>
							</div>
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faPersonChalkboard} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Formation</p>
							</div>
						</div>
						
						<a href="/contact?q=sec" className="btn p-2 w-full text-center primary">Faites un devis pour votre boutique</a>
					</div>
				</div>
			


				<div data-aos="fade-up" className="responsive-card overflow-hidden !p-0">
					<img src="images/services/option-custom.webp" alt="" className="object-cover"/>
					<div className="p-6">
						<h3 className="font-bold text-3xl mb-1">Site sur-mesure 🛠️</h3>
						<p className="text-xl font-light">Sur devis exclusivement</p>
						<p className="pt-4 pb-6">Une solution ultra-personnalisée pour des <b>projets complexes et ambitieux</b>.</p>
						
                        <div className="responsive-card-list">
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faGear} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Solution personnalisée</p>
							</div>
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faDna} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Évolutif et adaptable</p>
							</div>
                            <div className="responsive-card-list-item">
								<FontAwesomeIcon icon={faCloudArrowDown} className="text-xl text-black/80 dark:text-white"/>
								<p className="m-0">Compatibilité API</p>
							</div>
						</div>

						<a href="/contact?q=aw" className="btn p-2 w-full text-center primary">Discutons de votre projet sur-mesure</a>
					</div>
				</div>
			</div>	
        </div>
    );
    }

export default SitesView;