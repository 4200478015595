import { Routes, Route } from 'react-router-dom';
// Layout
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
// UI
import { useFlashMessage } from './context/FlashMessageContext';
import FlashMessage from "./components/UI/FlashMessage";

// Home pages
import HomeView from './pages/home/HomeView';
import ServicesView from './pages/services/ServicesView';
import AboutView from './pages/about/AboutView';
import ContactView from './pages/contact/ContactView';
import BlogView from './pages/blog/BlogView';

// Services
import VitrineView from './pages/products/VitrineView';

// Helpers
import AspectView from './pages/helpers/AspectView';

// Main Style
import './assets/styles/App.css';
import NotFound from './pages/NotFound';
import LayoutView from './components/layout/LayoutView';
import RgpdView from './pages/legal/RgpdView';
import LegalNoticeView from './pages/legal/LegalNoticeView';
import RgpdConsent from './components/UI/RgpdContent';

function App() {

  const { flashMessage, flashType } = useFlashMessage();

  return (
    <>
      {flashMessage && <FlashMessage message={flashMessage} type={flashType} />}
      <RgpdConsent />
      <Navigation />
      <main id="top" className="pt-16">
        <Routes>

          <Route path="/aspect" element={<AspectView />} />
          <Route path="/layout" element={<LayoutView />} />

          <Route path="/" element={<HomeView />} />
          <Route path="/blog" element={<BlogView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/services" element={<ServicesView />} />

        {/* SERVICES */}
          <Route path="/site-vitrine" element={<VitrineView />} />
          <Route path="*" element={<NotFound />} />

          {/* LEGAL STUFF */}
          <Route path='/politique-confidentialite' element={<RgpdView  />} />
          <Route path='/mentions-legales' element={<LegalNoticeView  />} />

        </Routes>
        {/* <aside className="w-24 bg-green-500"></aside> */}
      </main>
      <Footer />
    </>
  );
}

export default App;
