import React , {useEffect }from "react";
import Hero from "./Hero";
// import ProjectForm from "./ProjectForm";
import AOS from 'aos';
import 'aos/dist/aos.css';
import GoogleReviews from "./components/GoogleReviews";

function HomeView() {
    useEffect(() => {
        document.title = "Agantar - Créateur de Solutions numériques"
        setTimeout(() => {
            AOS.init({
                duration: 800,
                easing: 'ease-in-out',
                once: true
            });
        }, 100)
      }, [])

    return (
        <div id="homeView">
            <Hero className="mt-8 mb-16"/>

            <div className="page md:mb-32 mb-16">
                <div className="flex items-stretch xl:gap-16 gap-4 justify-center flex-wrap">

                  <div data-aos="fade-up" className="rounded-xl lg:p-10 p-6 bg-gray-100 dark:bg-gray-700 lg:w-80 w-64 text-center ">
                      <h4 className="text-7xl pb-4">🎯</h4>
                      <h3 className="text-3xl font-bold mb-2">Sur-Mesure</h3>
                      <ul className="list-none m-0 p-0">
                        <li>Design personnalisé</li>
                        <li>Adapté à tous les écrans</li>
                        <li>Adapté à votre budget</li>
                      </ul>

                  </div>

                  <div data-aos="fade-up" data-aos-delay="200" className="rounded-xl lg:p-10 p-6 bg-gray-100 dark:bg-gray-700 lg:w-80 w-64 text-center">
                      <h4 className="text-7xl pb-4">🚀</h4>
                      <h3 className="text-3xl font-bold mb-2">Rapide</h3>
                      <ul className="list-none m-0 p-0">
                        <li>En ligne en temps record</li>
                        <li>Livraison garantie ou remboursée*</li>
                      </ul>
                  </div>

                  <div data-aos="fade-up" data-aos-delay="300" className="rounded-xl lg:p-10 p-6 bg-gray-100 dark:bg-gray-700 lg:w-80 w-64 text-center">
                      <h4 className="text-7xl pb-4">🔑</h4>
                      <h3 className="text-3xl font-bold mb-2">Accessible</h3>
                      <ul className="list-none m-0 p-0">
                        <li>Livré clé en mains</li>
                        <li>Gestion simplifiée</li>
                        <li>Assistance complète</li>
                      </ul>

                  </div>
                </div>
            </div>


            <div className="max-w-4xl mx-auto md:mb-32 mb-16 px-4">
                <h2 data-aos="fade-right" className="text-center">Saviez-vous que <span className="text-agantar-light-blue text-5xl">75%</span> des internautes jugent la crédibilité d’une entreprise à la <span className="underline text-agantar-light-blue">qualité de son site web</span> ?</h2>
                <p className="text-center text-xl max-w-2xl mx-auto my-12">Votre site web est votre vitrine sur le web, accessible 24h/24. Que ce soit pour présenter vos services ou vendre vos produits, optez pour un site moderne, personnalisé et adapté à vos besoins.</p>
                <a href="/site-vitrine" className="block btn-cta primary mx-auto">Trouvez votre site vitrine</a>
            </div>
                       

            <div id="calendly" className="bg-agantar-blue py-24">
                <div className="max-w-4xl mx-auto">
                    <h2 data-aos="fade-right" className="text-center text-white">Vous avez un projet ?<br/>Parlons-en !</h2>
                    <div className="flex gap-6 justify-center items-stretch flex-wrap mt-12">

                        <a href="https://calendly.com/gabriel-endeka/30min" className="block !max-w-xs responsive-card text-center hover:scale-105 hover:shadow-xl transition-all duration-300">
                            <p className="text-7xl mb-2">🗓️</p>
                            <h3 className="font-semibold mb-1">Prendre rendez-vous</h3>
                            <p className="">Planifiez une session<br/> via <b>Calendly</b>.</p>                     
                        </a>

                        <a href="contact" className="block !max-w-xs responsive-card text-center hover:scale-105 hover:shadow-xl transition-all duration-300">
                            <p className="text-7xl mb-2">✉️</p>
                            <h3 className="font-semibold mb-1">Me Contacter</h3>
                            <p className="">Téléphone, email, formulaire...<br/> à votre guise.</p>
                            
                        </a>
                    </div>
                    
                </div>
            </div>

            <div className="py-24 px-4">
                <h2 data-aos="fade-right" className="text-center">Témoignages clients</h2>
                <GoogleReviews/>
            </div>

            <p className="opacity-80 text-xs page px-4 py-2 mt-8">* Agantar s'engage à vous rembourser une partie du montant du projet en cas de dépassement du délai de livraison convenu.</p>

        </div>
    );
}

export default HomeView;