const CompareTitle = ({ title, size, best }: { 
    title: string,
    size: number,
    best?: number
 }) => {
    const data = [];
    for (let i = 0; i < size -1 ; i++) {
        data.push(false);
    }
    if (best) {
        data[best-1] = true;
    }

    return (
        
        <div className="flex bg-gray-50 dark:bg-gray-700 rounded-l-xl">
        <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-3 py-4 font-bold text-lg">{title}</div>
        { data.map((item, index) => (
            <div key={index} className={`
                flex-1 p-2  text-center 
                ${item ? ' border-agantar-light-blue' : ''}
                ${index === size - 1 ? 'border-x-2' : 'border-l-2'}
            `}></div>
        ))}
    </div>
    );
}

export default CompareTitle;