import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';

interface CompareProps {
    title: string;
    values: (boolean | string)[];
    children?: React.ReactNode;
    last?: boolean;
}

const Compare: React.FC<CompareProps> = ({ title, values, children, last }) => {
    const [detailVisible, setDetailVisible] = useState(false);
        
    return (
         <div className={`flex ${ last ? '' : 'border-b-2'}`}>
            <div className="lg:flex-[0_0_45%] flex-[0_0_33%] p-3">
                <div className="flex items-center justify-between">
                    <p>{title}</p>
                    { children && <button className='font-bold' onClick={() => setDetailVisible(!detailVisible)}>
                        <FontAwesomeIcon icon={faChevronDown} className={`transition ${detailVisible ? 'rotate-180' : ''}`} />                        
                    </button>}
                </div>
                <p className={`text-sm py-3 pr-6 text-black/60 dark:text-white/80 ${detailVisible ? 'block' : 'hidden'}`}>
                    {children}
                </p>
            </div>
            { values.map((value, index) => (
                <div key={index} className={`flex-1 border-l-2 flex items-center justify-center text-sm ${index === values.length - 1 ? 'border-r-2 border-agantar-light-blue blue' : ''}`}>
                    { typeof value === 'boolean' ? ( value ? 
                    <FontAwesomeIcon icon={faCheck}className='text-green-600'/> 
                    :  
                    <FontAwesomeIcon icon={faXmark}className='text-gray-300'/>) 
                    : value }
                </div>
            ))}
        </div>
    );
    }

export default Compare;