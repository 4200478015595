import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigue vers la page précédente
  };

  return (
    <div className="page">
        <button
          onClick={handleGoBack}
          className="text-black/60 dark:text-white/50 hover:text-agantar-light-blue rounded px-2 py-1 transition-colors duration-200 flex items-center gap-1"
          >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm group-hover:pr-2"/>
          Retour
        </button>
      </div>
  );
};

export default BackButton;