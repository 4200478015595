import React, { useEffect, useRef } from 'react';
import '../../../assets/styles/pages/home/hero.css';

const ParallaxComputer = ({ className}: { className?:string}) => {
    const laptopRef = useRef<HTMLImageElement>(null);
    const shadowRef = useRef<HTMLImageElement>(null);
    const smartphoneRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
    // Parallax effect
    const laptop = laptopRef.current;
    const shadow = shadowRef.current;
    const smartphone = smartphoneRef.current;

    const smartphoneRatio = 2.5;
    const shadowRatio = 1.5;

    if (laptop && shadow && smartphone) {
        const parallax = (e: MouseEvent) => {
        const x = (window.innerWidth - e.pageX * 2) / 100;
        const y = (window.innerHeight - e.pageY * 2) / 100;

        laptop.style.transform = `translate(${x}px, ${y}px)`;
        shadow.style.transform = `translate(${x * shadowRatio}px, ${y * shadowRatio}px)`;
        smartphone.style.transform = `translate(${x * smartphoneRatio}px, ${y * smartphoneRatio}px)`;
        };

        window.addEventListener("mousemove", parallax);


        return () => {
        window.removeEventListener("mousemove", parallax);
        };
    }
    });

    return (
        <div className={`${className} relative select-none ratio-1-1`}>
                <img ref={laptopRef} src="images/home/web/web-laptop.webp" alt="" className='hero-laptop w-full h-full object-contain' />
                <img ref={shadowRef} src="images/home/web/web-shadow.webp" alt="" className="hero-shadow parralax-shadow z-1 top-0 absolute"/>
                <img ref={smartphoneRef} src="images/home/web/web-smartphone.webp" alt="" className="hero-smartphone z-2 top-0 absolute"/>
        </div>
    )
}

export default ParallaxComputer;