const AspectView = () => {
    return (
        <div className="page">
            <h1>AspectView</h1>
            <h2>H2 Title</h2>
            <h3>H3 Title</h3>
            <h4>H4 Title</h4>
            <h5>H5 Title</h5>

            <p>Body
                <br/>
                <b>Body bold</b>
                <ul>
                    <li>Liste 1</li>
                    <li>Liste 2</li>
                    <li>Liste 3</li>
                </ul>

                <ol>
                    <li>Liste 1</li>
                    <li>Liste 2</li>
                    <li>Liste 3</li>
                </ol>
            </p>

            <h6>Body small
                <br/>
                <b>Body small bold</b>
            </h6>
            
            <caption>Caption</caption>

        
        </div>
    );
}
export default AspectView;