import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { FlashMessageProvider } from './context/FlashMessageContext';
import ScrollToTop from './components/UI/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
);
root.render(
  <FlashMessageProvider>
    {/* <React.StrictMode> */}
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    {/* </React.StrictMode> */}
  </FlashMessageProvider>,
);

reportWebVitals();
