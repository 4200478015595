import React, { useRef, useEffect, useState} from "react";
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTooth,  faBicycle, faBook, faBowlingBall, faBriefcaseMedical, faBus, faCake, faCamera, faCampground, faCarSide, faCartShopping,  faComputer, faCouch, faFish, faFutbol, faGamepad, faGlasses, faGuitar, faHelmetSafety, faIceCream, faKey, faMicrophoneLines, faMugHot, faPaintRoller, faPizzaSlice, faPlane, faPlug, faShirt, faShop, faStar, faTractor, faTruck, faWrench, IconDefinition, faMartiniGlass } from "@fortawesome/free-solid-svg-icons";
import "../../assets/styles/pages/home/hero.css";
import ParallaxComputer from "./components/ParallaxComputer";

const shuffleIcons = (icons: IconDefinition[]) => {
    return [...icons].sort(() => Math.random() - 0.5);
}
const icons = [
    faStar, faTooth, faShop, faCamera, faCarSide, faWrench, faMugHot, faIceCream, faCampground, faBicycle, faPizzaSlice, faPaintRoller, faGlasses, faTruck, faShirt, faGamepad, faCake, faMicrophoneLines, faHelmetSafety, faComputer, faPlug,  faCouch, faMartiniGlass, faPlane, faBriefcaseMedical, faFish, faGuitar, faKey, faCartShopping, faBus, faFutbol, faBook, faBowlingBall, faTractor
]

const Hero: React.FC<{ className?: string }> = ({ className }) => {
    const iconListRef = useRef<HTMLDivElement>(null);
    const iconContainerRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [shuffledIcons] = useState(shuffleIcons(icons).slice(0, 10));

    useEffect(() => {
        const iconContainer = iconContainerRef.current;
        const iconList = iconListRef.current;

        if (iconList && iconContainer) {
            // Time and stop in milliseconds
            const transitionTime = 800;
            const stillTime = 1500;

            // Duplicate last element
            const firstIcon = iconList.children[0];
            if( firstIcon) {
                iconList.appendChild(firstIcon.cloneNode(true));
            }
        
            const stepY = iconContainer.offsetHeight;
            const totalHeight = (shuffledIcons.length * stepY) + stepY;
            let posY = 0;    

            const startLoop = () => {
                intervalRef.current = setInterval(() => {
                posY += stepY;
                iconList.style.transform = `translateY(${posY}px)`;
                if( posY + stepY >= totalHeight) {
                    clearInterval(intervalRef.current!);
                    setTimeout(() => {
                        posY = 0; // Reviens en haut
                        iconList.style.transition = "none";
                        iconList.style.transform = `translateY(${posY}px)`;
                        setTimeout(() => {
                            iconList.style.transition = `all ${transitionTime}ms`;
                            startLoop();
                        }, 50);
                    }, transitionTime - 50);
                }
                }, stillTime); // Pause de 2 secondes entre chaque élément
            }
            startLoop(); // Démarrer la boucle la première fois

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        }
    }}, [shuffledIcons]);

    return (
        <div id="hero" className={className}>
            <div className="w-full max-w-7xl mx-auto relative lg:flex items-center px-6 lg:justify-between">  

                <div className="lg:flex-[0_0_35rem] sm:max-w-[35rem] flex flex-col sm:gap-8 gap-4 mx-auto items-center lg:items-start max-w-[320px]">
                    <Logo className="fill-agantar-blue dark:fill-white w-11/12"/>
                    <div className="select-none ">
                        <p className="sm:text-6xl xs:text-4xl text-3xl font-bold w-fit mx-0">Construisons un <span className="text-agantar-light-blue">Site Web</span></p> 
                        <div className="relative -mt-1">
                            <p className="sm:text-6xl xs:text-4xl text-3xl font-bold">à votre image</p>
                            <div ref={iconContainerRef} className="icon-container">
                                <div ref={iconListRef} className="icon-list text-agantar-light-blue">
                                    {shuffledIcons.map((icon, index) => (
                                        <FontAwesomeIcon key={index} icon={icon} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <a 
                        href="/site-vitrine" 
                        className="block btn-cta primary">Votre Site Vitrine Clé en Main</a>
                    </div>
                </div>


                <ParallaxComputer className="flex-grow lg:block hidden"/>

            </div>
        </div>
    );
}

export default Hero;