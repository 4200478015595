interface priceData {
    total: number,
    monthly: number,
    discount?: number
}

const PriceBlock = ({ data, monthlyFee, compared }: {
    data: priceData,
    monthlyFee: boolean,
    compared?: boolean
}) => {
    // const discountMonthly = Math.round( (total/12) * 1.1);

    const getDiscountPct = () => {
        const totalMonthly = data.monthly * 12;
        const totalDiscount = totalMonthly - (data.discount ?? data.total);
        const discountPct = (totalDiscount / totalMonthly) * 100;
        return Math.floor(discountPct) + '%';
    }

    const getDiscountEur = () => {
        const totalMonthly = data.monthly * 12;
        const totalDiscount = totalMonthly - (data.discount ?? data.total);
        return totalDiscount;
    }

    if( compared ) {
        
        return monthlyFee ? 
            (<>
                <p><span className="text-xl font-semibold">{data.monthly}€</span><span className="text-sm">/mois*</span></p>
                <caption className="text-center px-4">Économisez <b>{getDiscountPct()}</b> avec le paiement unique !</caption>
            </>)
            :
            (<>
                <p><span className="text-xl font-semibold">{data.discount ?? data.total}€</span></p>
                <caption className="text-center px-4">Payez une seule fois et économisez <b>{getDiscountEur()}€</b> !</caption>
            </>)
        
    } else {
        return (
            <div className="price mt-1 mb-4">
                <p className="text-xl">Votre site pour 
                    { monthlyFee ? 
                        <> <span className='font-semibold text-xl'>{data.monthly}</span>€<span className="text-base">/mois*</span></> :
                        <> 
                        { data.discount && <span className="discountedPrice"> {data.total}€ </span>}
                        <span className='font-semibold text-2xl'> {data.discount ?? data.total}</span>€</>
                    }
                </p>

                <caption className="text-sm">
                    { monthlyFee ? 
                        <>Économisez <b>{getDiscountPct()}</b> avec le paiement unique !</> 
                        : 
                        <>Payez une seule fois et économisez <b>{getDiscountEur()}€</b> !</>
                    }
                </caption>

            </div>
        );
    }
}

export default PriceBlock;