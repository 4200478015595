import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import '../../assets/styles/legal/RgpdConsent.css';

const COOKIE_NAME = "rgpd_consent"; // Nom du cookie

declare global {
    interface Window {
      dataLayer: any[];
      gtag?: (...args: any[]) => void;
      ["ga-disable-G-XXXXXXXXXX"]?: boolean; // Remplace par ton ID GA
    }
  }

const RgpdConsent: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(COOKIE_NAME);
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

    useEffect(() => {
        if (fadeOut) {
        setTimeout(() => {
            setShowBanner(false);
        }, 500);
        }
    }, [fadeOut]);

  const handleConsent = (accepted: boolean) => {
    Cookies.set(COOKIE_NAME, accepted ? "accepted" : "declined", { expires: 180 }); // Expire après 180 jours
    setFadeOut(true);

    if (accepted) {
      activateGoogleAnalytics();
    } else {
      disableGoogleAnalytics();
    }
  };

  const activateGoogleAnalytics = () => {
    if (!window.dataLayer) {
        window.dataLayer = [];
      }
    function gtag(...args: any) {
      window.dataLayer.push(args);
    }
    gtag("consent", "update", {
      analytics_storage: "granted",
    });
  };

  const disableGoogleAnalytics = () => {
    window[`ga-disable-G-XXXXXXXXXX`] = true; // Remplace par ton ID Analytics
  };

  if (!showBanner) return null; // Cache la bannière si le cookie est déjà défini

  return (
    <div className={`${ fadeOut ? 'slideDown' : 'slideUp'} fixed bottom-0 left-0 w-full bg-gray-900 text-white p-4 shadow-md flex flex-col md:flex-row justify-between items-center z-[999]`}>
      <p className="text-sm mb-2 md:mb-0">
        Ce site utilise des cookies pour améliorer votre expérience et analyser son audience.
      </p>
      <div className="flex gap-3">
        <button
          onClick={() => handleConsent(true)}
          className="bg-agantar-light-blue hover:bg-agantar-blue text-white px-4 py-2 rounded transition-colors"
        >
          Accepter
        </button>
        <button
          onClick={() => handleConsent(false)}
          className="bg-red-800 hover:bg-red-900 text-white px-4 py-2 rounded transition-colors"
        >
          Refuser
        </button>
      </div>
    </div>
  );
};

export default RgpdConsent;