import '../assets/styles/UI/404.css';
import BackButton from '../components/UI/BackButton';

const NotFound = () => {

    return (
        <>
            <BackButton />
            <div className="page flex items-center justify-center flex-col h-full last">
                <h1 className="text-7xl mb-0 blue">404</h1>
                <h3>Cette page n'existe pas...</h3>

                <div className="tv mt-8 relative">
                    {/* Chemin d'image corrigé pour fonctionner avec React */}
                    <img src="/images/404/tv.webp" alt="Télévision statique" className="w-96" />
                    <div className="screen">
                        <img src="/images/404/noise.gif" alt="noise" className="noise"/>
                    </div>
                
                </div>
            </div>
        </>
    );
};

export default NotFound;