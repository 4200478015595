import SitesView from "./components/SitesView";
import RepairView from "./components/RepairView";
import DevelopmentView from "./components/DevelopmentView";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const ServicesView = () => {
    
    useEffect(() => {
        document.title = "Agantar - Offre de service"
    }, [])

    AOS.init();

    return (
        <>
        <div className="page">
            <h1 className='blue text-center mb-10'>Offre de service</h1>

                <SitesView className="mb-16"/>    

                <RepairView className="mb-16"/>

                <DevelopmentView className="mb-16"/>


        </div>

        <div className="bg-agantar-blue w-full mt-12 text-white py-16 px-4">
			<div data-aos="fade-up" className="max-w-3xl text-center mx-auto">
				<p className="title text-center mb-6">Vous hésitez sur le type de solution adaptée à votre projet ?</p>
            	<p className="text-left text-xl mb-8">Je suis là pour vous aider à faire le bon choix. Discutons ensemble de vos besoins et trouvons la solution idéale !</p>
				<a href="/contact?q=ot" className="btn-cta dark">Contactez-moi</a>
			</div>
		</div>
        </>

        
    );
    }

export default ServicesView;