const RgpdView = () => {
    const date = '11/02/2025'
    return (
        <div className="page px-4">
            <div className="blue text-center">
                <h1 className='mb-0'>Politique de Confidentialité</h1>
                <h2>et Protection des Données (RGPD)</h2>
            </div>

            <section>
                <h3 className="pt-12 pb-3">1. Introduction</h3>
                <p>Bienvenue sur <b>Agantar</b>. La protection de vos données personnelles est une priorité. Cette politique vous informe sur la collecte, l'utilisation et la protection de vos données conformément au <b>Règlement Général sur la Protection des Données (RGPD)</b>.</p>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">2. Responsable du Traitement des Données</h3>
                <p><b>Gabriel CONRAIRIE</b></p>
                <p>Email : <a href="mailto:contact@agantar.com">contact@agantar.com</a></p>
                <p>Téléphone : +33(0)6.58.00.87.65</p>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">3. Données collectées et finalité</h3>
                <h4 className="font-medium text-xl">3.1 Formulaire de Contact</h4>
                <ul>
                    <li><b>Données collectées :</b> Nom, prénom, entreprise, email, téléphone, message.</li>
                    <li><b>Finalité :</b> Répondre à vos demandes et échanger sur vos projets.</li>
                    <li><b>Durée de conservation :</b> 1 an après la dernière interaction.</li>
                </ul>
                
                <h4 className="font-medium text-xl pt-4">3.2 Google Analytics</h4>
                <ul>
                    <li><b>Données collectées :</b> Adresse IP anonymisée, navigation sur le site, pages visitées.</li>
                    <li><b>Finalité :</b> Analyser la fréquentation et améliorer l'expérience utilisateur.</li>
                    <li><b>Durée de conservation :</b> 14 mois.</li>
                    <li><b>Gestion :</b> <a href="https://tools.google.com/dlpage/gaoptout">Désactiver Google Analytics</a></li>
                </ul>
                
                <h4 className="font-medium text-xl pt-4">3.3 Cookies et traceurs</h4>
                <ul>
                    <li><b>Données collectées :</b> Cookies techniques et de suivi.</li>
                    <li><b>Finalité :</b> Fonctionnement du site et amélioration de l'expérience utilisateur.</li>
                    <li><b>Durée de conservation :</b> 6 mois.</li>
                </ul>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">4. Partage des données</h3>
                <p>Vos données personnelles <b>ne sont pas vendues, louées ou cédées à des tiers</b>. Elles peuvent être partagées uniquement avec :</p>
                <ul>
                    <li>Prestataires techniques (hébergement, outils de suivi).</li>
                    <li>Obligations légales si requis par la loi.</li>
                </ul>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">5. Sécurité des données</h3>
                <p>Nous mettons en place des <b>mesures techniques et organisationnelles</b> pour protéger vos données contre toute perte, altération ou accès non autorisé.</p>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">6. Vos droits</h3>
                <p>Conformément au RGPD, vous disposez des droits suivants :</p>
                <ul>
                    <li><b>Droit d'accès :</b> Obtenir une copie de vos données personnelles.</li>
                    <li><b>Droit de rectification :</b> Corriger vos informations inexactes.</li>
                    <li><b>Droit à l'effacement :</b> Demander la suppression de vos données.</li>
                    <li><b>Droit d'opposition :</b> Refuser l'utilisation de vos données à certaines fins.</li>
                    <li><b>Droit à la portabilité :</b> Récupérer vos données dans un format lisible.</li>
                </ul>
                <p>Pour exercer vos droits, contactez-nous à <a href="mailto:contact@agantar.com">contact@agantar.com</a>.</p>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">7. Contact et réclamations</h3>
                <p>Pour toute question ou réclamation concernant la gestion de vos données personnelles, vous pouvez nous contacter à <a href="mailto:contact@agantar.com">contact@agantar.com</a>.</p>
                <p>Si vous estimez que vos droits ne sont pas respectés, vous pouvez déposer une plainte auprès de la CNIL (<a href="https://www.cnil.fr">www.cnil.fr</a>).</p>
            </section>
            
            <section>
                <h3 className="pt-12 pb-3">8. Modification de la politique</h3>
                <p>Nous nous réservons le droit de modifier cette politique à tout moment. Toute mise à jour sera publiée sur cette page.</p>
            </section>

            <p className="py-12 text-sm">Dernière mise à jour : {date}</p>
            
        </div>
    );
}

export default RgpdView;